import Cookies from "js-cookie";
import { on, ready } from "../utils/listeners";

const mainShadow = document.querySelector(".mobile-nav-shadow");

const backgroundHome = document.querySelector(".js-menu-wrapper-home");

const html = document.documentElement;
const { body } = document;

on("click", ".js-menu-toggle-text", element => {
  const submenuContent = element
    .closest(".js-menu-toggle")
    .querySelector(".submenu-content");
  // The lower menu level's overflow needs to be hidden whenever the menu opens, otherwise it gets in the way of the swiping.
  submenuContent.scrollTop = 0;
  backgroundHome.classList.add("disable-scroll");
});

on("click", ".js-menu-toggle", element => {
  element.querySelector(".menu").classList.add("in");
  html.classList.add("overflow-y-hidden");
  body.classList.add("overflow-y-hidden");
});

on("click", ".js-menu-close", element => {
  element
    .closest(".menu-toggle")
    .querySelectorAll(".menu")
    .forEach(el => el.classList.remove("in"));
  html.classList.remove("overflow-y-hidden");
  body.classList.remove("overflow-y-hidden");
  backgroundHome.classList.remove("disable-scroll");
});

on("click", ".js-menu-back", element => {
  element.closest(".menu").classList.remove("in");
  backgroundHome.classList.remove("disable-scroll");
});

if (backgroundHome) {
  backgroundHome.addEventListener("scroll", () => {
    const yScrollPosition = backgroundHome.scrollTop;

    window.requestAnimationFrame(() => {
      if (yScrollPosition < 1) {
        mainShadow.classList.remove("show");
      } else {
        mainShadow.classList.add("show");
      }
    });
  });
}

const submenus = document.querySelectorAll(".submenu-content");

submenus.forEach(b => {
  const shadow = b.previousElementSibling;
  b.addEventListener("scroll", () => {
    const yScrollPosition = b.scrollTop;

    window.requestAnimationFrame(() => {
      if (yScrollPosition < 1) {
        shadow.classList.remove("show");
      } else {
        shadow.classList.add("show");
      }
    });
  });
});

const creditNotifierCookie = Cookies.get("userViewedCreditPage");
const userViewedCreditCookie = creditNotifierCookie === "true";

ready(() => {
  if (!userViewedCreditCookie) {
    const elements = document.getElementsByClassName("credit-notifier-mobile");

    if (elements.length > 0) {
      Array.from(elements).forEach(el => {
        el.classList.remove("hidden");
      });
    }
  }
});

on("click", "#credit", element => {
  let { userHasCredit } = element.dataset;

  userHasCredit = userHasCredit === "true";

  const notificationShown = !userViewedCreditCookie && userHasCredit;

  analytics.track("User Clicked Credit Dropdown", {
    notificationShown
  });
});
