import { leave } from "el-transition";

document.addEventListener("turbo:before-stream-render", event => {
  if (event.target.action === "remove") {
    event.preventDefault();
    const targetFrame = document.getElementById(event.target.target);
    leave(targetFrame).then(() => {
      targetFrame.remove();
    });
  }
});
