import { on, ready } from "../utils/listeners";

const loadAccountDrawer = async (el, e) => {
  e.preventDefault();
  const module = await import("../modules/accountDrawer");
  module.default();
};

ready(() => {
  on("click", ".js-account", loadAccountDrawer);
});
