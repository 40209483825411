import { ready } from "../utils/listeners";
import Carousel from "./initialiseCarousel";

ready(() => {
  const categoryCarousel = new Carousel(
    ".pdp-carousel-dot",
    ".pdp-carousel-cell"
  );

  categoryCarousel.init();
});
