import { on, ready } from "../utils/listeners";

const loadFavoritesDrawer = async (el, e) => {
  e.preventDefault();
  const module = await import("./favouritesDrawer");
  module.default();
};

ready(() => {
  on("click", ".js-favourites-trigger", loadFavoritesDrawer);

  const heartIconContainer = document.querySelector(".mini-heart-container");

  const performAddAnimation = () => {
    heartIconContainer.classList.remove("animate-removed");
    heartIconContainer.classList.add("animate-added");
    setTimeout(() => {
      heartIconContainer.classList.remove("animate-added");
    }, 1500);
  };

  const performRemoveAnimation = () => {
    heartIconContainer.classList.remove("animate-added");
    heartIconContainer.classList.add("animate-removed");
    setTimeout(() => {
      heartIconContainer.classList.remove("animate-removed");
    }, 1500);
  };

  window.addEventListener("addedFavourite", performAddAnimation);
  window.addEventListener("removedFavourite", performRemoveAnimation);
});
