import "../modules/turbo";
import "what-input";

import "../modules/segmentS2S";
import "../modules/tikTokS2S";
import "../modules/analytics";

import "../importers/accountDrawerImporter";
import "../importers/loginModalImporter";

import "../modules/favourites";
import "../modules/initialiseCategoryCarousel";
import "../modules/initialiseProductPageCarousel";
import "../modules/miniCart";
import "../modules/navFavouritesButton";
import "../modules/mobileNav";
import "../modules/mobileSearch";
import "../modules/productCarousel";
import "../modules/stickyNav";
import "../modules/turboStreamTransitions";
import "../modules/ujs";

import "../web-components/expanding-pill";

import "../controllers";

document.addEventListener("DOMContentLoaded", async () => {
  const importPromises = [];

  if (document.querySelector("#careers-app")) {
    importPromises.push(
      import("../modules/careers").then(module => module.default())
    );
  }

  if (document.querySelector("[data-toggle='collapse']")) {
    importPromises.push(
      import("../modules/collapse").then(module => module.default())
    );
  }

  if (document.querySelector(".js-countdown-block")) {
    importPromises.push(
      import("../modules/countdownTimer").then(module => module.default())
    );
  }

  if (document.querySelector(".designer-filter-button")) {
    importPromises.push(
      import("../modules/designers").then(module => module.default())
    );
  }

  if (document.querySelector(".js-dropdown-menu-block-cta")) {
    importPromises.push(
      import("../modules/dropdownMenuBlock").then(module => module.default())
    );
  }

  if (document.querySelector(".js-fold-load-more")) {
    importPromises.push(
      import("../modules/foldHomePage").then(module => module.default())
    );
  }

  if (document.querySelector(".fold-dropdown-header")) {
    importPromises.push(
      import("../modules/landingPageFoldDown").then(module => module.default())
    );
  }

  if (document.querySelector("video.lazy")) {
    importPromises.push(
      import("../modules/lazyloadVideo").then(module => module.default())
    );
  }

  if (document.querySelector(".product")) {
    importPromises.push(
      import("../modules/listings").then(module => module.default())
    );
  }

  if (document.querySelector("#mention-me-app")) {
    importPromises.push(
      import("../modules/mentionMe").then(module => module.default())
    );
  }

  if (document.querySelector("#banner-viewport")) {
    importPromises.push(
      import("../modules/multiplePromoBanners").then(module => module.default())
    );
  }

  if (document.querySelector("#order-details-banner")) {
    importPromises.push(
      import("../modules/orderBanner").then(module => module.default())
    );
  }

  if (document.querySelector(".product-carousels-landing-page-block")) {
    importPromises.push(
      import("../modules/productsCarouselBlock").then(module =>
        module.default()
      )
    );
  }

  if (document.querySelector(".js-promotion-signup")) {
    importPromises.push(
      import("../modules/promotionSignUp").then(module => module.default())
    );
  }

  if (document.querySelector(".quick-sample-button")) {
    importPromises.push(
      import("../modules/quickSample").then(module => module.default())
    );
  }

  if (document.querySelector(".recently-viewed-products-header-carousel")) {
    importPromises.push(
      import("../modules/recentlyViewedHeaderCarousel").then(module =>
        module.default()
      )
    );
  }

  if (document.querySelector(".shared-manual-register")) {
    importPromises.push(
      import("../modules/registerForm").then(module => module.default())
    );
  }

  if (document.querySelector(".can-show-saved-designs")) {
    importPromises.push(
      import("../modules/savedDesignBlock").then(module => module.default())
    );
  }

  if (document.querySelector(".shoppable-image-products")) {
    importPromises.push(
      import("../modules/shoppableImages").then(module => module.default())
    );
  }

  if (document.querySelector("[data-toggle='show-hide']")) {
    importPromises.push(
      import("../modules/showHide").then(module => module.default())
    );
  }

  if (document.querySelector(".pw-slide")) {
    importPromises.push(
      import("../modules/slides").then(module => module.default())
    );
  }

  if (document.querySelector("#oauth-social-login")) {
    importPromises.push(
      import("../modules/socialLogin").then(module => module.default())
    );
  }

  if (document.querySelector("#change-store-modal")) {
    importPromises.push(
      import("../modules/storeAlert").then(module => module.default())
    );
  }

  if (document.querySelector("#map")) {
    importPromises.push(
      import("../modules/storeLocator").then(module => module.default())
    );
  }

  if (document.querySelector(".marketing-pop-up-container")) {
    importPromises.push(
      import("../modules/subscribePopUp").then(module => module.default())
    );
  }

  // Execute all imports concurrently
  await Promise.all(importPromises);
});
